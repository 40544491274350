import React from "react";
import { FormattedNumber } from "react-intl";

const childrenRenderer = (price: string) => {
  const replaced = price.replace(
    /([^\d]*)(?=\d)/,
    `<small style="vertical-align:center;padding: 0 2px;">$1</small>`
  );
  return <span dangerouslySetInnerHTML={{ __html: replaced }} />;
};

type CurrencyProps = {
  value: number;
  maxFracts?: number;
  currencyCode?: string;
};

const Currency = ({
  value,
  maxFracts = 2,
  currencyCode = "USD"
}: CurrencyProps) => (
  <FormattedNumber
    value={value}
    style="currency"
    currency={currencyCode}
    currencyDisplay={"code"}
    children={childrenRenderer}
    maximumFractionDigits={maxFracts}
    minimumFractionDigits={maxFracts}
  />
);

export default Currency;
