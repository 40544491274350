import {
	Text,
	Flex,
	Heading,
	Box,
	Absolute,
	Button
} from '@yamsafer/styleguide';
import React from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Container from '../../components/Layout/Container';
import { format } from 'date-fns';
import PriceRenderer from './PriceRenderer';
import RoomDetailsIcon from '@yamsafer/icons/badge/RoomDetails';

const modalStyle = {
	overlay: {
		backgroundColor: 'rgba(255, 255, 255, 0.8)'
	},
	content: {
		background: 'transparent',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 15
	}
};

const StyledHtml = styled.div`
	font-size: 1rem;
	color: ${props => props.theme.colors.primary};
	li {
		text-transform: capitalize;
	}
`;

type NightlyRateProps = {
	night_rate: number;
	night_promotion_rate: number;
	night_chargeable_rate: number;
	night_rate_before_tax: number;
	night_chargeable_rate_before_tax: number;
	night_date: number;
};

type NightlyRatesProps = {
	baseRate: number;
	promotionRate?: number;
	isPromotion: boolean;
	isPrepaid?: boolean;
	nightlyRates: NightlyRateProps[];
};

const NightlyRates = ({ baseRate, nightlyRates }: NightlyRatesProps) => {
	return (
		<Box>
			<Heading>
				<FormattedMessage
					id="nightlyRatesTitle"
					defaultMessage="Price Details"
				/>
			</Heading>

			{nightlyRates.map((rate, index) => (
				<Box key={`nightly_rates_${index}`}>
					<Flex alignItems="center" justifyContent="space-between">
						<Text color="primary">
							{format(
								new Date(rate.night_date * 1000),
								'MMM D, YYYY'
							)}
						</Text>
						<Text color="primary">
							<PriceRenderer value={rate.night_promotion_rate} />
						</Text>
					</Flex>
					<Box as="hr" />
				</Box>
			))}

			<Flex alignItems="center" justifyContent="space-between">
				<Text fontSize={3} fontWeight="bold" color="primary">
					<FormattedMessage
						id="nihgtlyRatesTotal"
						defaultMessage="Total"
					/>
				</Text>
				<Text fontSize={3} fontWeight="bold" color="primary">
					<PriceRenderer value={baseRate} />
				</Text>
			</Flex>
		</Box>
	);
};

type SectionProps = {
	title?: React.ReactNode;
	html?: string;
	list?: string[];
};

const Section = ({ title, html, list }: SectionProps) => (
	<Box mb={5}>
		{title && <Heading>{title}</Heading>}

		{html && (
			<StyledHtml
				dangerouslySetInnerHTML={{
					__html: html
				}}
			/>
		)}

		{list && (
			<ul>
				{list.map((item, index) => (
					<Text key={item + '_' + index} as="li" color="primary">
						{item}
					</Text>
				))}
			</ul>
		)}
		<hr />
	</Box>
);

type RateDetailsAndPolicyProps = {
	isOpened: boolean;
	onRequestClose: () => void;
	accommodationName: string;

	isPrepaid: boolean;
	isPromotion: boolean;
	nightlyRates: NightlyRateProps[];

	baseRate: number;
	promotionRate: number;

	rateBenefits: string[];
	extraBedPolicy: string[];
	cancellationPolicy: string;
	checkinInstructions: string;
};
const RateDetailsAndPolicy = ({
	isOpened,
	onRequestClose,

	accommodationName,

	isPrepaid,
	isPromotion,
	nightlyRates,

	baseRate,
	promotionRate,

	rateBenefits,
	extraBedPolicy,
	cancellationPolicy,
	checkinInstructions
}: RateDetailsAndPolicyProps) => {
	return (
		<Modal
			style={modalStyle}
			isOpen={Boolean(isOpened)}
			onRequestClose={onRequestClose}
		>
			<Container>
				<Absolute top={5} end={5} onClick={onRequestClose}>
					<Button
						m={0}
						py={'2px'}
						width={36}
						height={36}
						fontSize={'x-large'}
						variant="transparent"
					>
						&times;
					</Button>
				</Absolute>

				<Flex alignItems="center" flexDirection="column">
					<Box my={40}>
						<RoomDetailsIcon width={96} height={102} />
					</Box>

					<Heading as="h1" mb={2}>
						{accommodationName}
					</Heading>
					<Heading as="h3">
						<FormattedMessage
							id="detailsAndPolicyTitle"
							defaultMessage="Details & Policy"
						/>
					</Heading>
				</Flex>

				<Flex flexDirection={['column', 'row']}>
					<Box width={[1, 17 / 24]} me={'25px'}>
						<Section
							title={
								<FormattedMessage
									id="cancelatonPolicyTitle"
									defaultMessage="Cancellaton Policy"
								/>
							}
							html={cancellationPolicy}
						/>

						<Section
							title={
								<FormattedMessage
									id="extraBedPolicyTitle"
									defaultMessage="Children & Extra Beds Policy"
								/>
							}
							list={extraBedPolicy}
						/>

						<Section
							title={
								<FormattedMessage
									id="rateBenefitsTitle"
									defaultMessage="Rate Benefits"
								/>
							}
							list={rateBenefits}
						/>

						<Section html={checkinInstructions} />
					</Box>
					<Box width={[1, 7 / 24]}>
						<NightlyRates
							promotionRate={promotionRate}
							nightlyRates={nightlyRates}
							baseRate={baseRate}
							isPromotion={isPromotion}
							isPrepaid={isPrepaid}
						/>
					</Box>
				</Flex>
			</Container>
		</Modal>
	);
};

export default RateDetailsAndPolicy;
