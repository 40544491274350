import styled from "styled-components";
import { Box } from "@yamsafer/styleguide";

const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media (min-width: 544px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 940px;
  }
  @media (min-width: 1200px) {
    max-width: 1060px;
  }
`;

export default Container;
